.pointList {
  background-color: #fff !important;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  max-height: calc(100vh - 85px - 75px);
  min-height: calc(100vh - 85px - 75px);
  height: calc(100vh - 85px - 75px);
  /* header = 80px, searchbar = 75px*/
}

.dragging {
  background-color: #fff !important;
  filter: drop-shadow(0px 7px 20px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 1px solid #765ee3;
}
.group {
  position: relative;
}

.group:before {
  content: "";
  position: absolute;
  z-index: 0;
  width: 7px;
  top: 36px;
  bottom: 25px;
  left: 4px;
  background: #dde5e9;
}

.paymentModalBtn {
  display: flex;
}

.rejectSection {
  display: flex;
  justify-content: center;
  color: #ce656b;
}

.orderDetails {
  min-width: 225px;
  max-width: 225px;
}

.groupLabel {
  color: #808080;
  font-weight: 500;
  font-size: 0.75rem;
}

.groupLabel span {
  margin-left: 10px;
}

.singlePoint {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  margin-left: 5px;
  width: calc(100% - 5px);
  text-align: left;
  padding: 3px 10px 5px 5px;
}

.singlePoint:hover {
  background: #f7f7f7;
  box-shadow: 0 1px 2px 1px rgb(0 0 0 / 12%);
}
.dragBtn:hover {
  cursor: move;
}
.dragImg {
  opacity: 0;
}
.singlePoint:hover .dragImg {
  opacity: 1;
}
.singlePointDataWrapper {
  min-width: 200px;
  max-width: 200px;
}
.address {
  display: flex;
}
.single-point-address-wrapper {
  min-height: 20px;
  max-height: 20px;
  max-width: 225px;
  width: 225px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 20px;
  align-self: center;
  font-size: 0.9rem;
}
.single-point-address-wrapper {
  position: relative;
}
.startingPointWrapper {
  display: flex;
  position: relative;
}
.startingPointContainer {
  margin-left: 10px;
  flex: 1;
  margin-bottom: 5px;
}
.startingPoint {
  margin-left: 8px;
}
.startingPointWrapper .purpleDot,
.endingPointWrapper .purpleDot {
  margin-top: 7px;
  margin-left: 0;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #765ee3;
  background: #ddd;
}

.endingPointWrapper .purpleDot {
  margin-top: 13px;
}

.startingPointWrapper .purpleDot::before,
.endingPointWrapper .purpleDot::before {
  margin-top: 4px;
  margin-left: 4px;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #765ee3;
  content: "";
}

.dotContainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.purpleDot {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid #765ee3;
  background: #ddd;
  margin-top: 13px;
  margin-left: 1px;
  z-index: 2;
}
.endingPointWrapper {
  display: flex;
  width: 100%;
  margin-left: 1px;
  margin-top: 10px;
}
.endingPoint {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 20px 0 12px;
}
.select-wrapper {
  position: relative;
  left: -5px;
}
.select-wrapper:hover {
  background: transparent;
}
.select-wrapper:before {
  left: -17px;
}
.travel-time {
  font-size: 10px;
  color: #808080;
  text-align: right;
  font-weight: 500;
  margin-right: 10px;
}
.single-point:focus,
.selected {
  box-shadow: 0 0 0 1px #765ee3;
  background: transparent;
}
.selected:hover {
  box-shadow: 0 0 0 1px #765ee3, 0 1px 2px 1px rgb(0 0 0 / 12%);
  background: #f7f7f7;
}
.routePoints {
  position: relative;
  left: -1px;
}
.durationSelect {
  background: transparent;
  border-color: transparent;
  border-radius: 4px;
  max-width: fit-content;
}
.durationSelect:hover {
  border-color: #ddd;
}
.durationSelect:focus {
  border-color: #765ee3;
  outline: none;
}
.label {
  color: #808080;
  font-weight: 500;
  font-size: 0.75rem;
  margin-left: 30px;
}

.btnContainer {
  display: flex;
  align-items: center;
  margin-left: 23px;
  margin-top: 10px;
}

.addBtn {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-right: 6px;
}

.addBtn img {
  filter: none;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.addBtn:first-child img {
  filter: brightness(0%);
}

.stopover-point {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding-right: 5px;
}

.greyDot {
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 4px solid #ccc;
  z-index: 2;
  background: #333;
  margin-left: 2px;
  margin-bottom: 13px;
}

.searchBarContainer {
  background-color: #fff;
  padding: 15px;
  height: 75px;
  min-height: 75px;
  max-height: 75px;
  width: 100%;
}
.searchInput {
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
}
.searchBarList {
  background: #ffffff;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.15), 0px -1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px 0px 0px;
}
.singlePointIndexBox {
  min-width: 22px;
  margin-right: 4px;
}
.singlePointIndex {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid #ddd;
  margin-top: 5px;
  padding: 0 4px;
  background: transparent;
  font-size: 12px;
}
.startingPointWrapper .singlePoint:hover {
  box-shadow: none;
  background: transparent;
}
.separator {
  display: inline-flex;
  width: 4px;
  min-width: 4px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 4px;
  margin: 0 6px;
}

.switchContainer {
  margin-bottom: 30px;
}

.carLabel {
  color: #6b6b6b;
  font-size: 0.81rem;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.2;
}

.inputErrorBorder {
  border-color: #ff6e75;
}

.validationInfo {
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: #d58d01;
}
.commentsImg {
  width: 12px;
  height: 12px;
}
.routeLabel {
  cursor: pointer;
  pointer-events: visible;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  color: #fff;
}
.routeSignature {
  color: #333 !important;
}

.routeLabel:hover {
  opacity: 0.7;
}

.routeSignatureWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
}

.buttonWrapper {
  margin-left: 25px;
}

.item {
  padding: 0;
}

.item:hover {
  opacity: 1;
}

.searchBarItem {
  padding: 6px 14px;
}
.searchBarAddress {
  max-width: 280px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.disabled {
  opacity: 0.5;
}
.currentRoute {
  background-color: #dae3e6;
  opacity: 1 !important;
}
.currentRoute span {
  color: #333 !important;
}

.awaitingLabel {
  padding-top: 2px;
}

.collectionHours {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  width: 125px;
}

.positiveCollectionHours {
  color: var(--Teal-6, #55bca6);
}

.negativeCollectionHours {
  display: flex;
  align-items: center;
}

.routeHoursInvalid {
  color: var(--Red-4, #e26b77);
}

.correctConfirmationRange {
  background: var(--teal-10, rgba(65, 148, 136, 0.1));
}

.invalidConfirmationRange {
  background: var(--red-10, rgba(225, 82, 65, 0.1));
}

.tooltip {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 1px;
  border-radius: 4px !important;
  margin-top: 2px !important;
  pointer-events: auto !important;
  opacity: 1 !important;
  padding: 6px !important;
  background-color: #252728 !important;
  width: 151px;
  display: flex;
  justify-content: center;
}
.tooltip:hover {
  visibility: visible !important;
}

.tooltip:global(.__react_component_tooltip.place-left)::before {
  top: 92% !important;
}
.tooltip:global(.__react_component_tooltip.place-left)::after {
  top: 92% !important;
}

.tooltipContent {
  color: var(--White-100, #fff);
  text-align: center;
  font-family: Cabin;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 140% */
}
