.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
  margin: 0;
}
.avatarSmall {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}
.avatar img {
  display: block;
  max-width: 100%;
}

.customTooltip {
  background-color: #000;
  padding: 4px 2px;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.03rem;
}
