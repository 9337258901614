.documentDetails {
  width: -webkit-fill-available;
  width: -moz-available;
  min-width: fit-content;
  min-width: -moz-fit-content;
  display: flex;
  align-items: center;
}
.mt1 {
  margin-top: 1px;
}

.transactionNum,
.subject,
.client {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.subject {
  color: #808080;
  font-size: 12px;
  font-weight: 500;
}
.client {
  font-size: 14px;
}

.amountToPay {
  font-weight: 700;
}

.signature {
  font-weight: 500;
  font-size: 12px;
}

.date {
  font-weight: 500;
  font-size: 12px;
}

.listAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
  border: 1px solid #ffffff;
}

.listAvatar img {
  display: block;
  max-width: 100%;
}

.avatarSmall {
  height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
}

.avatar img {
  display: block;
  max-width: 100%;
}

.assignee {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 700;
}

.reason {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.confirmInvoiceBtn {
  display: flex;
  justify-content: center;
}

.avatarBox {
  position: relative;
  display: flex;
  align-items: center;
  width: 300px;
  max-width: 300px;
  overflow: hidden;
}
.avatarBox {
  width: 124px;
  max-width: 124px;
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatar img {
  display: block;
  width: 100%;
}
.initialsAvatar {
  font-size: 0.7rem;
}

.confirmationBtn {
  max-height: 20px;
}

.morePeopleCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 2px;
  gap: 10px;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0px;
  top: 50%;
  transform: translateY(-5%);
  background: #d0c7fa;
  border: 1px solid #ffffff;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #856afe;
  flex: none;
  order: 4;
  flex-grow: 0;
  z-index: 4;
}
.morePeopleCounter {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25), 0px 0px 0px rgb(255 255 255);
}

.moreDataCounterOverride {
  width: max-content;
}
