.sectionEmpty {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.sectionEmptyTitle {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: normal;
}
.sectionEmpty .icon {
  opacity: 0.5;
}

.paymentNumber {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
  font-weight: 700;
  color: black;
}

.bankTransactionNumber {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 76px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.provider {
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px; /* 120% */
  letter-spacing: normal;
}

.date {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #808080;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatarSmall {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
}
.avatar img {
  display: block;
  max-width: 100%;
}

.tableRowPaymentsReturn {
  background-image: linear-gradient(
    45deg,
    #fbf4d6 5.56%,
    #ffffff 5.56%,
    #ffffff 50%,
    #fbf4d6 50%,
    #fbf4d6 55.56%,
    #ffffff 55.56%,
    #ffffff 100%
  );
  background-size: 12.73px 12.73px;
}
