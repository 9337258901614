.fitContent {
  width: max-content;
}

.datePicker {
  z-index: 52;
}

.date {
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  border: 1px solid #ddd;
  margin: 0 0 20px;
  padding: 0 0 0 5px;
  background: transparent;
  cursor: pointer;
  appearance: listbox;
  width: 100%;
}

.date:focus {
  box-shadow: #765ee3 0px 0px 0px 1px;
  outline: none;
}

.submitBtn {
  width: 100%;
}
