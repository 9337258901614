.finishedItem {
  border: 1px solid var(--success400);
}

.inProgressItem {
  border: 1px solid var(--yellow400);
}

.cancelledItem div {
  color: var(--neutralBlack48) !important;
}

.unloadingHeader {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  gap: 10px;
  height: 26px;
  border-bottom: 1px solid var(--grey25);
}

.workspaceHeaderBody {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  gap: 10px;
  height: 56px;
  border-bottom: 1px solid var(--neutralBlack12);
}

.signature {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 560px;
}

.ramp {
  display: flex;
  justify-content: center;
  width: 60px;
}

.progress {
  width: 90px;
  text-align: right;
}

.lineItemContainer {
  padding: 8px 15px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.tableContainer {
  max-height: 55vh;
  overflow: scroll;
}

.lineItemTitle {
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 10px;
}

.lineItemSection {
  padding: 8px 0;
}

.lineItemHeader {
  display: flex;
  align-items: center;
  height: 38px;
  border-bottom: 1px solid var(--neutralBlack12);
  gap: 10px;
  padding: 8px 15px 4px 15px;
}

.lineItemHeaderGeneral {
  display: flex;
  align-items: center;
  width: 340px;
  gap: 10px;
}

.lineItemHeaderBuyer {
  width: 305px;
}

.closeBtn {
  height: 42px;
}

.tableContainer {
  border-bottom: 2px solid var(--neutralBlack12);
}

.orderHeader {
  display: flex;
  height: 34px;
  align-items: center;
  padding: 8px 15px 4px 15px;
  gap: 10px;
  border-bottom: 1px solid var(--neutralBlack12);
}

.orderHeaderGeneralInfo {
  display: flex;
  align-items: center;
  width: 240px;
  gap: 8px;
}

.orderHeaderSignature {
  width: 120px
}

.orderHeaderCustomer {
  width: 470px;
}

.orderHeaderAddress {
  width: 200px;
}

.tableArea {
  max-height: 55vh;
  overflow: scroll;
}

.cancelledItem {
  background-image: linear-gradient(
    -45deg,
    var(--warning32) 5.56%,
    var(--neutralWhite100) 5.56%,
    var(--neutralWhite100) 50%,
    var(--warning32) 50%,
    var(--warning32) 55.56%,
    var(--neutralWhite100) 55.56%,
    var(--neutralWhite100) 100%
  );
  background-size: 12.73px 12.73px;
  opacity: 0.6;
}

.addIndexBtnContainer {
  height: 34px;
  padding: 8px 16px 0px 16px;
  gap: 10px;
  width: 688px;
}
