.wrapper {
  position: fixed;
  background: var(--grey800);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px 0px 0px;
  bottom: 11px;
  right: 60px;
  display: flex;
  padding: 12px 16px;
  height: 50px;
  align-items: center;
  min-width: 245px;
  justify-content: space-between;
}

.openWrapper {
  left: 945px;
}

.productsListWrapper {
  background: var(--neutralWhite100);
  height: 700px;
  left: 945px;
  right: 60px;
  bottom: 11px;
  position: fixed;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.productsListPanelHeader {
  background: var(--grey800);
  border-radius: 4px 4px 0px 0px;
  position: fixed;
  height: 50px;
  left: 945px;
  right: 60px;
  padding: 12px 16px;
}

.productsListBody {
  position: fixed;
  height: 656px;
  bottom: 11px;
  padding: 8px 0px;
  left: 945px;
  right: 60px;
  overflow: auto;
}

.filters {
  position: fixed;
  height: 60px;
  left: 945px;
  right: 60px;
  margin-top: 50px;
  padding: 8px 0px;
}

.filtersContent {
  height: 44px;
  padding: 0px 16px;
  gap: 16px;
  display: flex;
}

@media screen and (min-width: 1520px) {
  .productsListWrapper {
    height: 500px;
  }
  .productsListBody {
    max-height: 396px;
  }
}

@media screen and (max-width: 1520px) {
  .productsListWrapper {
    height: 400px;
  }
  .productsListBody {
    height: 296px;
  }
}

.orderSignature {
  color: var(--info700);
  width: fit-content;
  cursor: pointer;
}

.orderSignature:hover {
  color: var(--blue200);
  cursor: pointer;
}

.searchInput {
  font-size: 14px;
  font-family: "Sofia Sans", sans-serif;
  font-weight: 700;
  height: 38px !important;
}
.searchWrapper {
  width: 350px;
  max-width: 350px;
  min-width: 350px;
}

.tagFiltersWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
