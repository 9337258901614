.label {
  display: flex;
  padding: 0px 2px;
  align-items: center;
  position: absolute !important;
  left: 4px;
  top: -7px;
  background: #fff;
  color: var(--gray-3, #828282);
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  z-index: 2;
  height: 12px;
}
.date {
  border-radius: 4px;
  height: 28px;
  padding: 4px 5px;
  background: transparent;
  cursor: pointer;
  border: 1px solid #ffffff26;
  width: 100%;
  font-size: 14px;
  color: black;
}
.container {
  height: 28px;
  border-radius: 4px;
}
