.priceListSelect {
  width: 320px;
}

.inputSection {
  display: flex;
  gap: 10px;
  padding-left: 28px;
}

.input {
  width: 90px;
}

.dashedBorder {
  width: 100%;
  border: dashed 1px var(--neutralBlack24);
}
