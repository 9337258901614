.tag {
  position: relative;
  cursor: pointer;
  font-size: 14px;
}

.tagFill .tagInput:checked ~ .tagSelected {
  background: var(--deep-purple-1, #e7e1ff);
  border-color: transparent;
}
.tagFill .tagInput:checked ~ .tagSelected .checkMark {
  display: inline-flex;
}

.tagSelected {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 5px;
  font-size: 12px;
  letter-spacing: -0.03rem;
}

.tagInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
