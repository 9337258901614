.pageHeader {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #fff;
  font-family: "Sofia Sans", sans-serif;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}
.pageHeader .topBar {
  display: flex;
  padding: 0px 15px;
  align-items: center;
  gap: 15px;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}
.tabContainer {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
}
.tab {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  border-bottom: 2px solid transparent;
  color: var(--black-35, rgba(0, 0, 0, 0.5));
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
}
.tab:hover {
  color: #333;
  border-bottom: 2px solid #808080;
}
.tabActive {
  border-bottom: 2px solid var(--black, #000);
  color: var(--black, #000);
  font-weight: 700;
}
.divider {
  width: 0px;
  height: 20px;
  border-left: 1px solid var(--gray-5, #e0e0e0);
}
.pageHeader .bottomBar {
  display: flex;
  padding: 0px 15px;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  align-self: stretch;
}

.pageHeaderTitle {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  font-size: 20px;
  letter-spacing: -0.03rem;
  margin-top: 6px;
}

.bigTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  min-width: fit-content !important;
  margin: 0;
  letter-spacing: -0.48px;
}

.pageHeaderAdditionalMarginBottom {
  margin-bottom: 5px;
}

.avatar {
  height: 30px;
  min-height: 30px;
  width: 30px;
  min-width: 30px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* App dropdown */
.appLinkList {
  position: absolute;
  z-index: 30;
  top: 0px;
  right: -65px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  max-height: 412px;
  overflow: scroll;
  padding-bottom: 10px;
}
.appLinkSection {
  position: relative;
  width: 300px;
  height: 500px;
  padding: 10px;
}
.appLink {
  display: flex;
  width: 90px;
  height: 90px;
  padding: 20px 10px 8px 10px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  color: #333;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border-radius: 4px;
}

.appLink:hover {
  cursor: pointer;
  background: var(--black-5, rgba(0, 0, 0, 0.05));
}
.appLink:hover div {
  color: var(--deepPurple600) !important;
}
.appLink img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.disabledAppLink:hover {
  cursor: not-allowed !important;
  background: #fff;
}

.disabledAppLink {
  cursor: not-allowed !important;
  opacity: 0.4;
}
.disabledAppLink img {
  opacity: 0.4;
}

.disabledAppLink:hover div {
  color: var(--neutralBlack48) !important;
}

.bottomBarOptions {
  width: -webkit-fill-available;
}
.selector {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bottomBarFixedHeight {
  height: 48px;
  width: 200px;
}

.subtitle {
  white-space: nowrap;
}

.createBtnOverride {
  height: 26px;
}

.dividerMargin {
  margin: 0 5px;
}
