.pageMainContainer {
  display: flex;
  height: 100vh;
}

.navLeft {
  display: flex;
  width: 300px;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  background: #242227;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.2);
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}

.navLeftClosed {
  width: 60px;
}
.navLeftClosed .logoSection {
  padding: 8px 10px;
}
.navLeftClosed .moduleName,
.navLeftClosed .linkName,
.navLeftClosed .logoSection .logo {
  display: none;
}
.navLeftClosed .logoSection,
.navLeftClosed .moduleSection,
.navLeftClosed .link {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 0;
  align-self: stretch;
}

.navLeftInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.navLeftHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
  font-family: "Sofia Sans", sans-serif;
}

.logoSection {
  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  min-height: 42px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3);
}
.logoSection .logo {
  display: flex;
  height: 26px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.logoSection .logo img {
  display: flex;
  width: 54px;
  align-items: center;
  gap: 6px;
}

.moduleSection {
  display: flex;
  padding: 8px 10px;
  align-items: center;
  align-self: stretch;
  gap: 12px;
}

.moduleIconTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.moduleIcon {
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}
.moduleName {
  color: #1a1a1a;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.navLeftBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  font-family: "Sofia Sans", sans-serif;
}

.linkList {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.linkList h5 {
  padding: 6px 10px;
  letter-spacing: normal;
}

.link {
  display: flex;
  padding: 6px 10px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  min-height: 34px;
  color: #8a8a8a;
}
.link:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.link:focus {
  background: rgba(255, 255, 255, 0.15);
}
.linkActive {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.linkDisabled,
.linkDisabled:hover,
.linkDisabled:active,
.linkDisabled:focus {
  color: #555555;
  background-color: transparent;
  cursor: not-allowed;
}

.linkDisabled .icon {
  opacity: 0.5;
}

.link .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}
.linkName {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filtersSectionTitle {
  padding: 2px 15px;
  gap: 8px;
  display: flex;
  align-items: center;
  align-self: stretch;
  color: #fff;
  min-height: 34px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.filtersBody {
  width: 100%;
  margin-top: 5px;
}

.filtersBodyTitle {
  padding: 6px 15px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filtersBodyTitleLabel {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
}

.clearFiltersBtn {
  border-radius: 4px;
  color: #9f8aff;
  font-size: 10px;
  background: transparent;
  border: 1px solid transparent;
}

.clearFiltersBtn:hover {
  color: #9f8aff;
  background: var(--deep-purple-25, rgba(134, 107, 255, 0.25));
}
.clearFiltersBtn:focus {
  color: #9f8aff;
  border: 1px solid var(--deep-purple-15, rgba(134, 107, 255, 0.15));
  background: var(--deep-purple-35, rgba(134, 107, 255, 0.35));
}

.goBackBtn {
  border-radius: 4px;
  background: var(--white-15, rgba(255, 255, 255, 0.05));
  display: flex;
  width: 26px;
  height: 26px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.startViewDropdown {
  position: absolute;
  z-index: 1;
  top: 0px;
  right: -65px;
  border-radius: 8px;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}

.startViewBtn {
  border-radius: 0;
  font-family: "Sofia Sans", sans-serif;
}
