@keyframes listLoader {
  0% {
    opacity: 0.07;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.07;
  }
}

.loader {
  animation: listLoader 2s linear infinite;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.7);
}
