.toolbar {
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  background-color: rgb(38 38 38);
  position: fixed !important;
  z-index: 1;
}
