.datePickerInput {
  font-size: 14px;
  max-height: 28px;
  font-weight: 700;
  max-width: 120px;
  padding-left: 5px;
}

.timePickerInput {
  max-height: 28px;
}

.datePickerPopup {
  z-index: 22;
}

.suppliers {
  max-width: 400px;
}

.formHeight {
  height: 28px;
  font-size: 14px;
  font-weight: 700;
}

.errorWrapper {
  max-width: 100%;
  border-radius: 4px;
  background-color: var(--danger50);
}

.postCodeContainer {
  min-width: 120px;
  width: 120px;
  max-width: 120px;
  margin-right: 8px;
  margin-bottom: 16px;
  display: flex;
}
.cashOnDelivery {
  min-width: 120px;
  width: 120px;
  max-width: 120px;
}
.currency {
  min-width: 90px;
  width: 90px;
  max-width: 90px;
}
.inputContainer {
  margin-bottom: 16px;
  display: flex;
}

.editableCell {
  margin: 4px 0;
  cursor: pointer;
  max-height: 100%;
  min-height: 32px;
  max-width: 100%;
  width: 100%;
  display: flex;
  padding: 6px;
  align-items: center;
}

.editableCell:hover {
  border-radius: 4px;
  padding: 6px;
  background-color: var(--neutralBlack4);
}
.input {
  height: 28px;
  font-family: "Sofia Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 700;
}

.floatingLabel {
  height: 14px;
  font-family: "Sofia Sans", sans-serif !important;
  top: -7px;
  left: 5px;
  padding: 0 2px;
  font-size: 10px;
  font-weight: 700;
}

.piecesSectionMargin {
  padding: 0 15px;
  padding-top: 10px;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--grey25);
}

.tagWrapper {
  max-width: 79%;
  margin-right: 5px;
}

.historyStatus {
  background-color: var(--grey50);
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  bottom: -6px;
  right: -6px;
}
