.modalContainer {
  position: fixed;
  top: 100px;
  z-index: 1;
  min-width: 300px;
  width: 100%;
  max-width: 300px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}

.noBackground {
  background: none;
}

.input {
  height: 31px;
  padding-top: 5px;
}
