.drawerRight {
  min-width: 515px;
  max-width: 515px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  position: sticky;
  max-height: calc(100vh - 110px);
  right: 0;
  margin-top: 0px;
  transition: 0.2s ease-in-out;
  z-index: 3;
}
@media (max-width: 1279px) {
  .drawerRight {
    position: fixed;
    top: 144px;
    bottom: 0;
    max-height: 100vh;
    margin-top: 0;
  }
}
@media (max-width: 1535px) {
  .drawerRight {
    min-width: 485px;
    max-width: 485px;
  }
}
@media (min-width: 1920px) {
  .drawerRight {
    min-width: 585px;
    max-width: 585px;
  }
}
.closed {
  position: absolute;
  left: 100%;
}

.drawerInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 110px);
  position: relative;
}

.drawerSections {
  padding: 5px 20px 120px 10px;
}

.drawerContent,
.drawerBottom {
  position: relative;
}

.drawerSection {
  padding: 5px 10px 5px 5px;
}

.drawerBottom {
  z-index: 2;
  background: #f6f8f9;
  border-top: 1px solid #ddd;
  position: sticky;
  bottom: 0;
  padding: 5px 10px;
}
.drawerHeader {
  height: 40px;
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 3;
}

.listWrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
  position: relative;
}

.drawerSectionTitle {
  border-top: 1px solid #ddd;
  font-size: 1rem;
  color: #738992;
  padding: 10px 0;
}
.drawerHr {
  border-top: 1px solid #ddd;
  font-size: 1rem;
  color: #738992;
  padding: 10px 0;
}

.moduleListWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 1px;
  width: 100%;
}

.moduleListWrapperInner {
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-grow: 1;
}

/* New Right Panel drawer */

.rightPanel {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 60%;
  max-width: 720px;
  width: 720px;
  min-width: 550px;
  right: -100%;
  background: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  height: calc(100vh - 110px);
  letter-spacing: -0.03rem;
  line-height: 1.4;
  font-family: "Sofia Sans", sans-serif;
}
.rightPanelOpen {
  right: 0;
  height: calc(100vh - 88px);
}

.rightPanelHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.rightPanelBodyWithComments {
  height: calc(100vh - 232px);
  overflow: scroll;
}

.rightPanelBody {
  height: calc(100vh - 131px);
  overflow: scroll;
}

.sectionBorder {
  border-bottom: 1px solid #ddd;
}

.rightPanelSectionTitle {
  font-size: 14px;
  font-weight: 700;
  color: #808080;
  margin-bottom: 10px;
}

.rightPanelTableRow {
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}
.rightPanelTableBodyRow {
  width: 100%;
  padding: 6px 10px;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rightPanelTableBodyRow:hover {
  background: #edf0f9;
}
.rightPanelTableBodyRow:active {
  background: #d9e0f1;
}
.tableHeader {
  display: grid;
  font-size: 12px;
  gap: 10px;
  font-weight: 400;
  padding-bottom: 4px;
  padding-top: 4px;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}

.slidingPanelHeader {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 22px;
}

.slidingPanelHeaderShadow {
  box-shadow: 0 1px 2px rgba(169, 169, 169, 0.2);
}

.slidingPanelHeaderContent {
  transform: translateY(-120%);
  animation: slidePanelHeaderAnimation 0.3s forwards;
}

@keyframes slidePanelHeaderAnimation {
  to {
    transform: translateY(0)
  }
}

.panelHeaderDuringSlide {
  height: 43px;
}
