.sectionEmpty {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.sectionEmpty .icon {
  opacity: 0.5;
}
.sectionEmptyTitle {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: normal;
}
