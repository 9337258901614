.scrollableContent {
  height: calc(100vh - 41px);
  overflow: scroll;
}

.emptyList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 320px;
}
.emptyList .icon {
  height: 160px;
}
.emptyList .body14 {
  max-width: 600px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
  margin-bottom: 1rem;
}

.emptyListTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01rem;
  margin: 0;
  color: rgb(33, 37, 41);
}

.actionBtn {
  height: 26px;
}


/* FILTERS */

.tag {
  position: relative;
  cursor: pointer;
  font-size: 14px;
}

.tag .marker {
  position: static !important;
  transform: initial !important;
}

.tagInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.tagSelected {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 5px;
  font-size: 12px;
}
.tagRound .tagSelected {
  border-radius: 50%;
  padding: 4px;
}

.tagFill .tagInput:checked ~ .tagSelected {
  background: var(--deep-purple-1, #e7e1ff);
  border-color: transparent;
}
.tagFill .tagInput:checked ~ .tagSelected .checkMark {
  display: inline-flex;
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}
.tableHeaderBokDepartedRoutes,
.tableRowBokDepartedRoutes {
  padding: 4px 15px;
  gap: 10px;
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1fr 30px 90px 70px;
  padding: 4px 15px;
  gap: 10px;
}

.tableHeaderBokIncompleteRoutes,
.tableRowBokIncompleteRoutes {
  padding: 4px 15px;
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 70px 70px 70px 100px;
  padding: 4px 15px;
  gap: 10px;
}

.tableHeaderBokLatestSmsMessages,
.tableRowBokLatestSmsMessages {
  padding: 4px 15px;
  gap: 10px;
  display: grid;
  grid-template-columns: 100px 1fr 70px 110px 40px;
  padding: 4px 15px;
  gap: 10px;
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  font-size: 12px;
  gap: 10px;
  width: 100%;
  min-width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.tableRow:hover {
  background: #edf0f9;
}
.tableRow:active /* on click */ {
  background: #d9e0f1;
}
.tableRow {
  display: grid;
  padding: 4px 15px;
  gap: 10px;
}
.tableRow .date {
  font-weight: 500;
  font-size: 12px;
}
.list .tableRow {
  font-size: 12px;
}
.tableRow div {
  position: relative;
}

.list .signature,
.subject,
.tableRow .date {
  font-weight: 500;
  font-size: 12px;
}

.noResultsOverwrite {
  height: 374px;
  max-height: 374px
}

.filterLabelHeight {
  height: 20px;
}

.labelNoWrap {
  white-space: nowrap;
}

.input {
  font-size: 14px;
  font-family: "Sofia Sans", sans-serif;
  font-weight: 700;
  height: 28px !important;
}
.wrapper {
  width: 184px;
  max-width: 184px;
  min-width: 184px;
}
