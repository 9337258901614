.spinner {
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 150px);
}
.isConfirmed {
  opacity: 0.85;
  background: #dde1e2;
}
.datePickerPopup {
  z-index: 22;
}

.rightPanel {
  position: absolute;
  right: 0;
}

.createManufacturingOrderOpacity {
  opacity: 0.6 !important;
}
