.attributes {
  gap: 2px;
  display: flex;
  width: 100%;
  align-items: center;
}

.tooltipAttributes {
  gap: 2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
