.checkboxCell {
  width: 26px;
  min-width: 26px;
}
.checkboxContainer {
  display: inline-flex;
  position: relative;
  padding: 0;
  padding-left: 30px;
  margin-bottom: 2px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  width: 20px;
  height: 20px;
}
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.checkMark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 3px solid #9a9a9a;
  border-radius: 50%;
}
.checkMark:after {
  content: "";
  position: absolute;
  display: block;
  left: 7px;
  top: -4px;
  width: 7px;
  height: 15px;
  border: solid #9a9a9a;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkboxContainer input:checked ~ .checkMark:after,
.checkboxContainer input:checked ~ .checkMark {
  border-color: #01c9d5;
}
.signatureCell {
  width: 200px;
  min-width: 200px;
}
.detailsCell {
  width: -webkit-fill-available;
  width: -moz-available;
  min-width: fit-content;
  min-width: -moz-fit-content;
  font-size: 0.9rem;
}
.address {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
}
.statusesCell {
  width: 120px;
  min-width: 120px;
}
.avatarCell {
  width: 26px;
  min-width: 26px;
}
.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #ddd;
  margin: 9px 0;
  overflow: hidden;
}
.avatar img {
  display: block;
  max-width: 100%;
}
.noTime {
  margin-right: 6px;
  color: #777;
}
.noData {
  color: #777;
}
.border {
  border-left: 1px solid #777;
  margin-left: 4px;
  margin-right: 3px;
  width: 1px;
  height: 16px;
}

.routesReportBtn {
  max-height: 26px;
}

.routeName {
  font-size: 0.86rem;
  color: rgb(74, 74, 74);
  text-transform: lowercase;
}
.routeSignature {
  font-size: 1.1rem;
}
.routeSignature:hover,
.routeSignature:focus {
  color: #333 !important;
}

.divider {
  width: 4px;
  height: 4px;
  background: #9a9a9a;
  border-radius: 4px;
  margin: 0 5px;
}
.spinner {
  z-index: 1;
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 150px);
}
.driverCarSection {
  width: 450px;
  min-width: 450px;
  flex-direction: row-reverse;
}

.dateImg {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 4px;
}
.dateImg img {
  filter: contrast(0%) brightness(100%);
  width: 17px;
  height: 17px;
}

.routeLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  padding: 0 3px;
  opacity: 0.7;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

a {
  color: #;
}
