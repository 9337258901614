.gap1 {
  gap: 4px;
}
.gap2 {
  gap: 8px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  height: fit-content;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 8px;
}
.buttonRound {
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 0;
}
.buttonRound img {
  display: block;
  max-width: 16px;
}
.buttonBackgroundBlack {
  background: #333;
  color: #fff;
}
.buttonBackgroundTransparentBlack {
  background: rgba(0, 0, 0, 0.1);
  color: #333;
}
.buttonBackgroundTransparentWhite {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.buttonBackgroundWhite {
  background: #fff;
  color: #333;
}
.buttonBackgroundTransparent {
  background: transparent;
}
.buttonBackgroundTransparent:hover {
  background: #ddd;
}
.buttonBackgroundOrange {
  background: #d57e01;
}
.buttonBackgroundPrimary {
  background: #856afe;
  color: #fff;
}
.buttonBackgroundPrimary:hover {
  background: #6853ca;
  color: #fff;
  text-decoration: none;
}
.buttonBackgroundPrimary:focus {
  background: #5a48af;
  color: #fff !important;
  text-decoration: none;
}

/* Content */
.contentWrapper {
  display: grid;
  grid-template-columns: 50px auto;
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.48px;
}
.scrollXWrapper {
  display: flex;
  width: 100%;
  max-width: 100%;
  max-height: calc(100vh - 64px);
  overflow-x: scroll;
  padding-bottom: 40px;
  scroll-behavior: smooth;
}
.contentGroup {
  display: flex;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.cols {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 100vh - 64px;
  flex: none;
  flex-grow: 0;
}
.scrollYWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 40px;
  scroll-behavior: smooth;
  height: initial;
  padding-bottom: 0;
}
.morePeopleCounter,
.moreDataCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 2px;
  gap: 10px;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: #d0c7fa;
  border: 1px solid #ffffff;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #856afe;
  flex: none;
  order: 4;
  flex-grow: 0;
  z-index: 4;
}
.morePeopleCounter {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25), 11px 0px 0px rgb(255 255 255);
}

.customTooltip {
  background-color: #000;
  padding: 4px;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.03rem;
}
