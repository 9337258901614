.formHeight {
  height: 28px;
  font-size: 14px;
}

.inputTextLabel {
  font-size: 11px;
  padding: 1px 2px;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
}
.avatar img {
  display: block;
  width: 100%;
}

.initialsColor {
  background-color: #00cf91;
}

.inputSmall {
  max-width: 120px;
  display: flex;
  height: 28px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 14px;
  background: none;
}

.inputSmall:hover {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--deep-purple-4, #9f8aff);
}
.inputSmall:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--deep-purple-5, #8c73ff);
}

.inputSmall {
  padding: 0 10px;
  /* height: auto; */
  border-color: transparent;
}

.inputOnLightBg {
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  color: #333;
}

.inputLabel {
  display: flex;
  padding: 0px 2px;
  align-items: center;
  position: absolute !important;
  left: 4px;
  top: -7px;
  background: #242227;
  color: var(--gray-3, #828282);
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  height: 12px;
}

.inputLabelLight,
.inputOnLightBg ~ .inputLabel {
  background: #fff;
}

.datePickerLabel {
  top: -11px;
  font-size: 11px;
}

.customTooltip {
  padding: 4px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.03rem;
}

.select {
  position: relative;
  border-radius: 4px;
}
.select select {
  height: 38px;
  width: 100%;
  padding: 0px 5px;
  padding: 0px 5px 0 0;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: transparent;
  overflow: hidden;
}
.select select:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.select select:focus {
  outline: none;
}
.select.hasMarker .marker,
.select.hasAvatar .avatar {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}
.select.hasAvatar select {
  padding-left: 30px;
}
.select.hasIcon select,
.select.hasMarker select {
  padding-left: 20px;
}
.select.hasIcon .icon {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}
.select.hasIcon img {
  width: 16px;
  height: 16px;
}
.selectSmall select {
  height: 28px;
  font-size: 14px;
}
.selectSmall select:hover {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--deep-purple-4, #9f8aff);
}

.labelText {
  font-size: 12px;
  line-height: 12px;
  color: #808080;
  display: inline-flex;
  font-weight: 600;
}

.driverLabel {
  gap: 0.25rem;
}

.advancedSelectIcon {
  height: 24px;
  width: 24px;
}

.advancedSelectList {
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}

.notificationsOptionList {
  position: absolute !important;
  z-index: 1;
  top: calc(100% - 5px);
  right: -135px;
  min-width: max-content;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}

.notificationsOptionList .search {
  width: 100%;
  min-width: 100%;
}

.optionRow {
  height: 42px;
}

/* .moreRouteOptionsList {
  position: absolute !important;
  z-index: 1;
  top: calc(100% + 15px);
  right: 0;
  min-width: max-content;
  width: 100%;
  background: #fff;
  max-height: 300px;
  overflow: scroll;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}

.moreRouteOptionsList .search {
  width: 100%;
  min-width: 100%;
} */

.moreRouteOptionsList {
  position: absolute !important;
  z-index: 1;
  top: calc(100% + 17px);
  right: -83px;
  min-width: max-content;
  width: 324px;
  max-height: 300px;
  overflow: scroll;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
}
.moreRouteOptionRow {
  cursor: pointer;
}

.moreRouteOptionsList .search {
  width: 100%;
  min-width: 100%;
}
.moreRouteOptionRow img {
  width: 18px;
  min-width: 18px;
}
.moreRouteOptionRow:hover {
  background: rgba(0, 0, 0, 0.05);
}

.moreRouteOptionRow a {
  color: #222;
}
.moreRouteOptionRow img {
  width: 18px;
  min-width: 18px;
}
.moreRouteOptionRow:hover {
  background: rgba(0, 0, 0, 0.05);
}

.errorLabel {
  margin-bottom: 8px;
  font-size: 13px;
  color: #808080;
}

.notificationPanelContainer {
  max-height: 34px;
}

.notificationSectionTitle {
  letter-spacing: -0.015rem;
  line-height: 18px;
}

.createdByContainer {
  max-height: 32px;
}

.carLabel {
  max-height: 36px;
}

.btnReady {
  color: #333;
  background: var(--teal-4, #6de5b9);
}

.panelHeaderSizing {
  max-height: 43px;
}

.textarea {
  background-color: transparent;
  border-radius: 4px;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 700;
  line-height: 19px;
  height: 38px;
  width: 100%;
  outline: none;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
