.row {
  position: relative;
  border-bottom: 1px solid var(--neutralBlack12);
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
  border-top: 1px solid transparent;
  background-color: var(--neutralWhite100);
  padding: 0 15px;
}

.nested {
  border-bottom: 1px dashed var(--grey75);
}

.hoverable {
  cursor: pointer;
}

.hoverable:hover {
  background-color: var(--neutralBlack4);
  border: 1px solid var(--neutralBlack12);
}

.lastOpened {
  background-color: var(--blue25);
}

.selected {
  background-color: var(--indigo25);
}

.active {
  background-color: var(--deepPurple75);
}
