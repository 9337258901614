.buttons {
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 16px 15px;
}

.dashedBorder {
  width: 100%;
  border: dashed 1px var(--neutralBlack24);
}

.border {
  width: 100%;
  border: solid 1px var(--neutralBlack7);
  margin: 8px 0px 8px 0px;
}

.tagContainer {
  cursor: pointer;
}

.disabledTagContainer {
  cursor: not-allowed;
}

.errorMessage {
  color: var(--danger400);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px 0px 0px;
}

.attributeAccordion {
  color: var(--neutralBlack88);
}

.attributeAccordion button {
  padding: 0px;
}

.attributeAccordion span {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}
