.widerDrawer {
  max-width: 720px;
}
.approvalBtn {
  color: #333;
  background: var(--teal-4, #6de5b9);
}
.approvalBtn:hover {
  background: var(--teal-9, #82dbba);
}
.approvalBtn:focus {
  background: var(--teal-10, #98d8c0);
}

.filterChip {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 1px 3px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 2px;
  background: var(--deep-purple-7, #6651c6);
  color: #fff;
}
.filterChip img {
  display: block;
  height: 14px;
  width: 14px;
  min-width: 14px;
}
.FVsignature {
  line-height: 0.9;
}
.description {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.formHeight {
  height: 28px;
  font-size: 14px;
}

.input {
  height: 28px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  width: 300px;
}
