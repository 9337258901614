.attribute {
  padding: 2px 4px 1px;
  border: 1px solid #ddd;
  background-color: #d0d0d6;
  border-radius: 3px;
  margin: 3px 2px 3px 0;
  font-size: 12px;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: min-content;
}
.attribute span {
  margin-right: 2px;
  white-space: nowrap;
  font-weight: normal;
  color: #444;
}
.attribute strong {
  font-weight: 700;
  white-space: nowrap;
}

.attributesContainer {
  display: flex;
  flex-wrap: wrap;
}
.orderDate {
  font-size: 0.75rem;
  color: #808080;
  text-align: right;
  font-style: italic;
}
.divider {
  width: 4px;
  height: 4px;
  background: #9a9a9a;
  border-radius: 4px;
  margin: 0 10px;
}
.indexesContainer {
  max-height: 65vh;
  overflow: scroll;
}
.disabled {
  background-color: #e7e7e7;
  opacity: 0.5;
}
.info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signature {
  font-size: 0.95rem;
  text-align: left;
}

.modal {
  padding: 0;
  margin: 4vh auto;
}
.title {
  margin-left: 0;
  padding-left: 1.5rem;
  padding-top: 15px;
  padding-bottom: 1.5rem;
  font-size: 1.55rem;
  font-weight: bold;
}
.container {
  padding: 13px 1.5rem;
  display: grid;
  grid-gap: 10px;
  grid-template-areas: "a a a a";
  align-items: center;
  grid-auto-columns: minmax(0, 30px) minmax(0, 1.5fr) minmax(0, 1fr) minmax(0, 130px);
}

.manufacturer {
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
