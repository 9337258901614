.moduleListTable {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: none;
  flex: 1 1 auto;
  min-width: 390px;
  overflow: hidden;
  width: 100%;
  font-family: 'Sofia Sans', sans-serif;
  overflow-x: scroll;
}

.hideScrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  height: 30px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
  letter-spacing: -0.03rem;
  align-items: flex-end;
  width: fit-content;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
  justify-content: unset;
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  min-width: 100%;
  width: fit-content;
}
.tableRow:hover {
  background: #edf0f9;
}
.tableRow:global(.active) {
  background: #d9e0f1;
}
.tableRow:global(.highlighted) {
  background-color: #edf0f9;
}

.tableRow div {
  position: relative;
}

.moduleListBody .tableRow {
  font-size: 12px;
  font-weight: 500;
}

.tableHeader,
.tableRow {
  display: grid;
  padding: 4px 15px;
  gap: 10px;
}

.moduleListBody {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 1px;
  height: calc(100vh - 163px);
  overflow-y: scroll;
  letter-spacing: -0.03rem;
  width: fit-content;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
  justify-content: unset;
}
